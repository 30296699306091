"use client"

import { builderModeEnabledAtom } from "@/state"
import { useSetAtom } from "jotai"
import React from "react"

/**
 * We don't want the template itself to worry about setting builder mode or initializing the
 * webstoreConfig. This component is a simple way to do that while enabling the callers of Template
 * to remain server-side.
 *
 * This component blocks rendering of children until the builder mode is set.
 * Although this sounds drastic, the action's execution time is negligible and unnoticed by the user.
 * @param props
 * @returns
 */
export default function SetBuilderModeEnabledState(props: React.PropsWithChildren<{ enabled: boolean }>) {
	const setBuilderModeEnabled = useSetAtom(builderModeEnabledAtom)

	// Ensure the mode is set before rendering children
	React.useLayoutEffect(() => {
		setBuilderModeEnabled(props.enabled)
	}, [props.enabled])

	return <>{props.children}</>
}
