import FullScreen from "@/components/FullScreen"
import Loader from "@/components/Loader"

export default function Loading() {
	return (
		<FullScreen>
			<title>...</title>
			<Loader />
		</FullScreen>
	)
}
