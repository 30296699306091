"use client"
import Loading from "@/app/[webstoreName]/loading"
import SetBuilderModeEnabledState from "@/components/SetBuilderModeEnabledState"
import Template from "@/components/Template"
import Unavailable from "@/components/Unavailable"
import { PermalinkStatus } from "@/enums"
import { useGetWebstoreCatalogue, useGetWebstoreConfigByName } from "@/hooks/permalink"
import { PageParams, WebstoreConfig } from "@/types"
import { capitalize } from "@/utils"
import React, { useEffect } from "react"
import { useSetAtom } from "jotai/index"
import { webstoreNameAtom } from "@/state"

// the key must match the dynamic route section in brackets as seen in the file path
type PageProps = PageParams<{ webstoreName: string }>
const builderModeEnabled = false

const Page: React.FC<Readonly<PageProps>> = ({ params }) => {
	const {
		data: webstoreConfigResponse,
		isLoading: webstoreConfigLoading,
		isError: webstoreConfigError,
	} = useGetWebstoreConfigByName(params.webstoreName)
	const permalink = webstoreConfigResponse?.permalink
	const setWebstoreName = useSetAtom(webstoreNameAtom)

	useEffect(() => {
		if (webstoreConfigResponse) {
			setWebstoreName(params.webstoreName)
		}
	}, [webstoreConfigResponse])

	const {
		data: webstoreCatalogResponse,
		isLoading: webstoreCatalogueLoading,
		isError: webstoreCatalogueError,
	} = useGetWebstoreCatalogue(permalink?.owner, permalink?.webstoreConfig)
	const catalogueItems = webstoreCatalogResponse?.catalogueItems
	const products = catalogueItems?.products ?? []
	const services = catalogueItems?.services ?? []

	const error = webstoreConfigError || webstoreCatalogueError
	const webstoreConfig = permalink?.webstoreConfig
	const loading = webstoreConfigLoading || webstoreCatalogueLoading

	const unavailable = !(permalink?.status === PermalinkStatus.Active && (products.length || services.length))

	if (loading) {
		return Loading()
	}

	if (error) {
		return <div>Something went wrong</div> // TO DO
	}

	if (unavailable) {
		return <Unavailable webstoreName={permalink?.ecommStoreName}>Friendly not found page =)</Unavailable>
	}

	return (
		<SetBuilderModeEnabledState enabled={builderModeEnabled}>
			<title>{capitalize(params.webstoreName)}</title>
			<Template
				publicFacingWebstoreConfig={webstoreConfig as WebstoreConfig}
				webstoreName={params.webstoreName}
				products={products}
				services={services}
				isPublicFacing={true}
			/>
		</SetBuilderModeEnabledState>
	)
}

export default Page
