import Attention from "@/assets/Attention"
import iKTeal from "@/assets/footer-blue.svg"
import { Link, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import Image from "next/image"
import React, { PropsWithChildren } from "react"

type UnavailableProps = PropsWithChildren & {
	webstoreName?: string
}

const Unavailable: React.FC<UnavailableProps> = ({ webstoreName }) => {
	const theme = useTheme()
	const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
	return (
		<LayoutStack isDesktop={isDesktop}>
			<Paper sx={{ width: { sm: "23.5rem" }, maxWidth: "80vw" }}>
				<Stack alignItems={"center"} gap={3} sx={{ margin: { xs: "2rem 1.5rem", sm: "2rem" } }}>
					<Attention width={150} height={150} />
					<Typography variant={"h6"} textAlign={"center"} sx={{ fontSize: "1.25rem" }}>
						{heading(webstoreName)}
					</Typography>
					<Typography variant={"body1"} textAlign={"center"}>
						{description(webstoreName)}
					</Typography>
				</Stack>
			</Paper>
			<Stack alignItems={"center"} gap={3} sx={{ overflow: "hidden", position: "absolute", bottom: 0, width: "100%" }}>
				<Typography>
					Powered by{" "}
					<Link variant="body1" color={"action.active"} href="https://ikhokha.com" target="_blank">
						iKhokha
					</Link>
				</Typography>
				<Image src={iKTeal} alt="iK Logo" priority />
			</Stack>
		</LayoutStack>
	)
}

const heading = (webstoreName?: string) => (webstoreName ? "Website is unavailable!" : "Oops! Website not found")

const description = (webstoreName?: string) =>
	webstoreName ? (
		<>
			We’re sorry, the{" "}
			<Typography component={"span"} textTransform={"capitalize"}>
				{webstoreName}
			</Typography>{" "}
			website is currently offline. Please check back later.
		</>
	) : (
		<>
			It looks like the website you're trying to access doesn't exist. You may have mistyped the address, or the website may have
			been moved.
		</>
	)

const LayoutStack: React.FC<PropsWithChildren<{ isDesktop: boolean }>> = ({ isDesktop, children }) => {
	return (
		<Stack
			bgcolor={"common.white"}
			height={"100vh"}
			justifyContent={isDesktop ? "center" : "flex-start"}
			alignItems={"center"}
			paddingTop={isDesktop ? 0 : "5rem"}
		>
			{children}
		</Stack>
	)
}

export default Unavailable
